import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, Button } from "react-bootstrap";

import PropertyService from "../../services/property.service";
import MapTooltip from "../map/MapTooltip";

function ClaimParcels({
  claimedParcels,
  propertyName,
  setPropertyName,
  countyName,
  propertyCentroid,
  propertyBbox,
  claimModalOpen,
}) {
  const history = useHistory();

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const thisUserID = useParams()

  async function createProperty(parcels) {
    let index = parcels.findIndex(parcel => parcel.street_address !== undefined)
    let data = {
      'host': thisUserID.id?thisUserID.id:currentUserData.id,
      'title': propertyName,
      'address': {
        'street_address': parcels[index]?.street_address || null,
        'city': parcels[index]?.city || null,
        'county': countyName || null,
        'state': parcels[index]?.state || null,
        'zipcode': parcels[index]?.zipcode || null
      },
      'parcels': claimedParcels.map(parcel => parcel.parcelID),
      'property_centroid': propertyCentroid,
      'property_bbox': propertyBbox,
    }

    PropertyService.addProperty(data, authToken)
    .then(res => {
      history.push(`/dashboard/property/${res.data._id}`);
    })
    .catch(err => {
      // console.error(err);
    });

    localStorage.setItem("claimedParcels", JSON.stringify(parcels));
  }

  const [submitted, setSubmitted] = useState(false)
  const [newPropertyName, setNewPropertyName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitted(true)
  
    if (newPropertyName !== "") createProperty(claimedParcels)
  }
  
  return (
    <>
      <div className={`bg-primary text-white h-100 w-100 d-flex justify-content-center z-5 position-absolute ${claimModalOpen ? "d-block" : "d-none"}`} >
        <div className="bg-primary text-white h-100 d-flex justify-content-center align-items-center flex-column text-center">
          <h2 className="text-secondary fw-bolder mb-4 fs-5 w-75">
            {`You've selected ${claimedParcels.length === 1 ? "1 parcel" : `${claimedParcels.length} parcels`} for this property. Please provide a
              property name.`}
          </h2>
          <Form noValidate onSubmit={handleSubmit} className="w-75 mt-2" style={{ maxWidth: '500px' }}>
            <Form.Group>
              <Form.Control
                className="text-center"
                placeholder={"e.g., Silver Creek Ranch"}
                onChange={(e) => {
                  setNewPropertyName(e.target.value)
                  setPropertyName(e.target.value)
                }}                
                required
                isInvalid={submitted && newPropertyName === ''}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a property name.
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="my-3 px-5 btn btn-outline-secondary"
              type="submit"
              variant="primary"
            >
              Submit
            </Button>
          </Form>
          <div className="text-muted fs-6 w-75">
            Click submit to save your property.
          </div>
        </div>
      </div>
    <MapTooltip text={"Click to select one or more parcels, then click Submit to create your property."}/>
    </>
  );
}
export default ClaimParcels;
