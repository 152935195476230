import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import ListingService from '../../services/listing.service';

import { SearchListings } from '../../components/forms/searchPublicListings';
import { listingTypesDefinitions, masterCategoryDefinitions } from '../../listingTypes/listingTypes';
import { statesList } from "../../components/data/statesList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Col, Row, Button, Container, Card, Navbar } from 'react-bootstrap';

import PublicFooter from '../../components/PublicFooter';
import Preloader from '../../components/Preloader';


const Search = (props) => {

  const [queryStatusListings, setQueryStatusListings] = useState(false);
  const [unfilteredListingsList, setUnfilteredListingsList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  useEffect(() => {
    document.title = "Search Listings | REC-X";

    if ( !queryStatusListings ) {
      ListingService.getListingsPublic()
        .then(res => {
          setQueryStatusListings(res.status);
          setListingsList(res.body);
          setUnfilteredListingsList(res.body)
        });
      }
  }, []);


  let searchListingsFormData = {};
  let parsedHash = queryString.parse(window.location.hash);
  if ( parsedHash && Object.keys(parsedHash).length > 0 ) {
    // Filter out all falsy values ( "", 0, false, null, undefined )
    searchListingsFormData = Object.entries(parsedHash).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
  }

  // Use Category selection to set Activities
  if ( searchListingsFormData.hasOwnProperty('category') ) {
    let category = _.find(masterCategoryDefinitions, {'id': searchListingsFormData.category});
    searchListingsFormData.activity = category.listingTypes.join();
  }

  // if only 1 'activity' parsed from URL, convert to an array with that single value
  if ( typeof searchListingsFormData.activity === 'string' ) {
    const strVal = searchListingsFormData.activity;
    searchListingsFormData.activity = [];
    searchListingsFormData.activity.push(strVal);
  }

  // Run searchListings algo if hash parameters included in URL
  useEffect(() => {
    if ( parsedHash && Object.keys(parsedHash).length > 0 ) searchListings();
  }, [unfilteredListingsList]);

  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData); // Stringify form array into hash parmeters
    window.location.hash = queryHash; // Update search page URL

    let filteredListingsList = [];

    if ( Object.keys(searchListingsFormData).length > 0 ) {

      // Handle 'activity' checkbox filters
      Object.keys(searchListingsFormData).some(searchTerm => {
        if ( searchTerm === 'activity' ) {

          searchListingsFormData.activity.toString().split(',').map( (activity) => {

            _.filter(unfilteredListingsList, function(listing) {
              if ( listing.listingType && Object.keys(listing.listingType).length > 0 ) {
                let listing_type = listing.listingType;
                // Check if arrays match for least one 'activity'
                if ( Object.keys(_.intersectionWith([activity], listing_type, _.isEqual)).length ) {
                  // thisSearchMatches used to display 'activity' matche(s)
                  listing.thisSearchMatches = _.intersectionWith([activity], listing_type, _.isEqual);
                  // thisSearchRanking used to sort strongest 'activity' matches to top
                  listing.thisSearchRanking = Object.keys(listing.thisSearchMatches).length;
                  filteredListingsList.push(listing);
                }
              }
            });

          });
        }

        filteredListingsList = _.orderBy(filteredListingsList, ['thisSearchRanking'], ['desc']); // Sort multiple 'activity' matches to top using thisSearchRanking
        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        setListingsList(filteredListingsList);
        return null;
      });

      // Handle State filter
      if ( searchListingsFormData.state && Object.values(statesList).includes(searchListingsFormData.state) ) { // restrict to valid states
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {
          return ( listing.state === undefined || listing.state !== searchListingsFormData.state );
        });

        filteredListingsList = _.uniq(listingList); // Remove duplicates
        setListingsList(filteredListingsList);
      } else { // remove invalid state value
        delete searchListingsFormData.state;
      }

      // Handle Date Range filter
      if ( searchListingsFormData.start && searchListingsFormData.end ) {
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {

          let rejectListing = false;
          for (var m = moment(searchListingsFormData.start); m.diff(searchListingsFormData.end, 'days') <= 0; m.add(1, 'days')) {

            if ( listing.availableBookingDates.includes(m.format('YYYY-MM-DD')) ) {
              rejectListing = false;
              break;
            } else {
              rejectListing = true;
            }
          }

          return rejectListing;

        });

        filteredListingsList = _.uniq(listingList); // Remove duplicates
        setListingsList(filteredListingsList);
      }

      // Handle text search term
      if ( searchListingsFormData.searchText ) {
        _.filter(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {
          let selected = false; // prevent multiple positive hits on same a single listing object
          Object.values(listing).map(function(dataPoint) {
            if ( typeof dataPoint === 'string' ) {
              dataPoint = dataPoint.toLowerCase();
              if ( selected !== true && dataPoint.includes(searchListingsFormData.searchText.toLowerCase()) ) {
                filteredListingsList.push(listing);
                selected = true;
              }
            }
            return null;
          });
        });

        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        setListingsList(filteredListingsList);
      }

    } else { // If search parameters are removed, remove previous filters w/o requiring page refresh
      clearSearch();
    }
  }

  const clearSearch = () => {
    searchListingsFormData = {};
    setListingsList(unfilteredListingsList);
    window.history.replaceState(null, null, '#');
  }


  const selectCategory = (categoryID, button) => {
    // selected button .active
    let categoryButtons = document.querySelectorAll('.category-button');
    for ( let i=0; i < categoryButtons.length; i++ ) {
      categoryButtons[i].classList.remove('active');
      if ( categoryButtons[i].id == categoryID ) {
        categoryButtons[i].classList.add('active');
      }
    }

    let parsedHash = queryString.parse(window.location.hash);
    parsedHash.category = categoryID;
    delete parsedHash.activity; // clear Activity value(s) b/c they will be set upon reoload to match Category

    // update category & activites in URL
    window.location.hash = `#${queryString.stringify(parsedHash)}`;
    window.location.reload();
  }


  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/mapbox/streets-v12`,
        center: [-112.027031, 46.595806], // ToDo : calculate centeroid of results
        zoom: 5,
        pitch: 0
      });

      map.current.on('style.load', () => {
        map.current.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 10
        });
        map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      });

      map.current.addControl(new mapboxgl.NavigationControl());
    };

    if ( queryStatusListings === 200 && listingsList && (listingsList.length < unfilteredListingsList.length) || !Object.keys(parsedHash).length ) {
      listingsList.map( (listing) => {
        if ( listing.hasOwnProperty('propertyData') && Object.keys(listing.propertyData).length ) {
          var el = document.createElement('span');
          el.className = 'marker fa fa-mountain-sun fa-3x d-flex align-items-center justify-content-center';
          el.style.width = '5rem';
          el.style.height = '5rem';
          el.style.color = 'rgba(0, 0, 0, 0.7)';
          el.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
          el.style.borderRadius = '50%';
          el.style.border = 'solid 0.25rem white';
          el.style.backgroundImage = `url(${listing.gallery[0]})`;
          el.style.backgroundPosition = 'center';
          el.style.backgroundSize = 'cover';
          el.style.backgroundRepeat = 'no-repeat';

          var marker = new mapboxgl.Marker(el)
            .setLngLat(listing.propertyData.property_centroid)
            // .setPopup(popup)
            .addTo(map.current);

          marker.getElement().addEventListener('click', () => {
            let listings = document.querySelectorAll('[id*="listing-"]');
            for ( let i=0; i < listings.length; i++ ) {
              listings[i].classList.remove('active');
              if ( listings[i].id == `listing-${listing._id}` ) {
                listings[i].classList.add('active');
                listings[i].scrollIntoViewIfNeeded();
              }
            }
          })
        }
      });
    };
  }, [unfilteredListingsList, listingsList]);


  return (
    <>
      <Row className="my-4">
        <Col>
          <Navbar id="searchResults-categorySelector" className="bg-body d-flex flex-wrap align-items-start justify-content-evenly">
            {
              Object.values(masterCategoryDefinitions).map(function (c, i) {
                return (
                  <Button 
                    variant="outline-secondary"
                    key={c.id}
                    id={c.id}
                    className={`${(searchListingsFormData.hasOwnProperty('category') && searchListingsFormData.category === c.id)?'active':''} category-button d-flex flex-column align-items-center justify-content-start text-center border-2 py-3 mx-1 mb-2`} style={{width: '8rem', height: '8rem'}}
                    onClick={(button) => selectCategory(c.id, button)}
                  >
                    <FontAwesomeIcon icon={c.icon} size="3x" className="mb-2" />
                    <small className="d-block lh-sm text-primary">{c.title}</small>
                  </Button>
                );
              })
            }
          </Navbar>
        </Col>
      </Row>
      <Row className="px-4 my-4">
        <Col id="searchResults" xs={5}>
          {(queryStatusListings !== 200)
            ?
              <div className="mt-6" style={{height: '60vh'}}>
                <Preloader message="Loading..." logoSize={75} show={true} />
              </div>
            :
              <div id="searchResults-list" style={{height: '80vh', overflow: 'scroll'}}>
                <Row>
                  <Col>
                    {listingsList.length} Listings Found
                  </Col>
                </Row>
                <Row className="row-cols-1">
                  {
                    listingsList.map(function (listing) {

                      let primaryImage = ( listing.hasOwnProperty('gallery') && listing.gallery.length ) 
                        ? listing.gallery[0] 
                        : `https://picsum.photos/200/100?${Math.floor(Math.random()*100)}`;

                      return (
                        <Col className="mt-4" key={listing._id} id={`listing-${listing._id}`} style={{height: '7rem'}}>
                          <Link to={"/listing/"+listing._id}>
                            <Card border="light" className="bg-white shadow-sm h-100 overflow-hidden">
                              <Row className="g-0 h-100">
                                <Col md="4" className="img h-100" style={{backgroundImage: `url(${primaryImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></Col>
                                <Col>
                                  <Card.Body className="py-3">
                                    <h5 className="fw-bolder mb-0" style={{textShadow: '0 0 3px #fff'}}>{listing.title}</h5>
                                    <div className="price small">Starting Price ${listing.base_rate} / Guest</div>

                                    {listing.thisSearchMatches &&
                                      <div className="activities badges mb-1">
                                        {
                                          Object.values(listing.thisSearchMatches).map(function (activity) {
                                            return (
                                              <span className="activity badge bg-secondary py-1 m-1" key={activity}>{listingTypesDefinitions[activity].type.title}</span>
                                            );
                                          })
                                        }
                                      </div>
                                    }
                                    {listing.description}
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })
                  }
                </Row>
              </div>
          }
        </Col>

        <Col>
          <div style={{height: '80vh', width: '100%'}}>
            <div ref={mapContainer} className="w-100 h-100 rounded"></div>
          </div>
        </Col>
      </Row>

      <PublicFooter />

    </>
  );
};
export default Search;
