import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import _ from "lodash";

import { statesList } from "../../components/data/statesList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const SearchListings = (props) => {

  const [highlightSubmitButton, setHighlightSubmitButton] = useState(false);

  const [searchListingsFormData, setSearchListingsFormData] = useState({});
  useEffect(() => {
    setSearchListingsFormData(props.formData);
  }, [props.formData]);

  function setFormValues(e) {
    // update state of searchListingsFormData
    let currentSearchListingsFormData = searchListingsFormData;
    currentSearchListingsFormData[e.target.name] = e.target.value;
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }


  const [searchState, setSearchState] = useState(0);
  useEffect(() => {
    setSearchState(props.formData.state);
  }, [props.formData.state]);
  function setStateValue(e) {
    let currentSearchListingsFormData = searchListingsFormData;
    if ( Object.values(statesList).includes(e.target.value) ) {
      currentSearchListingsFormData[e.target.name] = e.target.value;
    } else {
      delete currentSearchListingsFormData[e.target.name];
    }
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    setSearchState(e.target.value);
  }


  const [activityFilterValues, setActivityFilterValues] = useState([]);
  useEffect(() => {
    setActivityFilterValues(props.formData.activity);
  }, [props.formData.activity]);

  function setActivityFilter(e) {
    const checkedState = e.target.checked;
    // store state into local
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};

    // if only 1 'activity' parsed from URL, convert to an array with that single value, then add subsequent values
    if ( typeof currentSearchListingsFormData['activity'] === 'string' ) {
      currentSearchListingsFormData['activity'] = [currentSearchListingsFormData['activity']];
      setActivityFilterValues([currentSearchListingsFormData['activity']]);
    }
    // if no 'activity' filters set, create array
    if ( !currentSearchListingsFormData.hasOwnProperty('activity') ) {
      currentSearchListingsFormData['activity'] = [];
    }

    if ( !currentSearchListingsFormData['activity'].includes(e.target.value) && checkedState ) {
      // Add new 'activity' value to array
      currentSearchListingsFormData['activity'] = _.concat(currentSearchListingsFormData['activity'], e.target.value);
      setActivityFilterValues(_.concat(currentSearchListingsFormData['activity'], e.target.value));
    } else {
      // Remove 'activity' from array if present
      currentSearchListingsFormData['activity'] = currentSearchListingsFormData['activity'].filter(el => el !== e.target.value);
      setActivityFilterValues(currentSearchListingsFormData['activity'].filter(el => el !== e.target.value));
    }

    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }


  const [dateRangeStart, setDateRangeStart] = useState(0);
  useEffect(() => {
    setDateRangeStart(props.formData.start);
  }, [props.formData.start]);
  const changeDateRangeStart = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeStart(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['start'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    // set next day after dateRangeStart
    changeDateRangeEnd(moment(e).add(1, 'day'));

    // auto-click End Date field
    document.querySelector('#dateRangeEnd input').focus();
  };

  const [dateRangeEnd, setDateRangeEnd] = useState(0);
  useEffect(() => {
    setDateRangeEnd(props.formData.end);
  }, [props.formData.end]);
  const changeDateRangeEnd = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeEnd(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['end'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  };

  var isValidEndDate = function(current) {
    // check date is NOT BEFORE user-selected start
    if ( dateRangeStart ) {
      if ( !current.isAfter( moment(dateRangeStart).add(-1, 'day') ) ) { return false; }
    }

    // check date is NOT BEFORE TODAY
    if ( !current.isAfter( moment().add(-1, 'day') ) ) { return false; }

    return true;
  };


  return (
    <>
      <Row className="justify-content-center my-5 mx-3 mx-md-0">
        <Col>
          <Form id="searchListings" onSubmit={ (e) => props.searchListings(e.preventDefault()) }>

            <Row>
              <Col className="mb-3">
                <Form.Group id="searchCategories" className="text-center">
                  {/*<Form.Label className="d-block text-center fw-bolder fs-4">Filter by Activity:</Form.Label>*/}
                  {
                    Object.values(props.listingTypesDefinitions).map(function (l, i) {
                      return (
                        <Form.Check
                          inline
                          type="checkbox"
                          label={l.type.title}
                          name="activity"
                          key={l.id}
                          id={l.id}
                          value={l.id}
                          checked={ (activityFilterValues && activityFilterValues.length > 0 && activityFilterValues.includes(l.id)) ? true : null }
                          onChange={setActivityFilter}
                        />
                      );
                    })
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row className="d-flex justify-content-evenly justify-content-lg-between">
              <Col xs={6} lg={2} className="mb-3 pe-0">
                <Form.Group id="dateRangeStart">
                  {/*<Form.Label className="d-block text-center fs-5">Start Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeStart}
                    closeOnSelect={true}
                    isValidDate={(current) => {return current.isAfter( moment().add(-1, 'day') )}} // check date is NOT BEFORE TODAY

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeStart ) ? moment(dateRangeStart).format("MM/DD/YYYY") : "" }
                          placeholder="Start Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeStart(e.target.value) }}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} lg={2} className="mb-3 ps-0">
                <Form.Group id="dateRangeEnd">
                  {/*<Form.Label className="d-block text-center fs-5">End Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeEnd}
                    closeOnSelect={true}
                    isValidDate={isValidEndDate}

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeEnd ) ? moment(dateRangeEnd).format("MM/DD/YYYY") : "" }
                          placeholder="End Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeEnd(e.target.value) }}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} lg={2} className="mb-3 px-lg-0">
                <Form.Group id="searchState">
                  {/*<Form.Label className="d-block text-center fs-5">State</Form.Label>*/}
                  <Form.Select id="state" name="state" value="MT" /*value={searchState}*/ onChange={setStateValue} onBlur={setStateValue}>
                    <option value="MT">Montana</option>
{/*
                    <option value={false}>State</option>
                    {
                      Object.keys(statesList).map(function (key, i) {
                        return (
                          <option value={statesList[key]} key={i}>{key}</option>
                        );
                      })
                    }
*/}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={6} lg={4} className="mb-3">
                <Form.Group id="searchText">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control name="searchText" defaultValue={props.formData.searchText && props.formData.searchText} type="text" placeholder="Search Term" onChange={setFormValues} onBlur={setFormValues} />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col className="mb-3 px-lg-0">
                <Button variant={highlightSubmitButton?'secondary':'outline-secondary'} type="submit" className="px-5 w-100">Search</Button>
              </Col>
            </Row>

            <Row>
              <Col className="text-center mb-3">
                {
                  props.clearSearch && Object.keys(searchListingsFormData).length > 0
                  ?
                    <Button variant="outline-danger" type="reset" className="px-5 mt-3" onClick={() => props.clearSearch()}>Clear</Button>
                  :
                    <div className="pt-4 pb-4 mt-2 mb-1"></div>
                }
              </Col>
            </Row>

          </Form>
        </Col>
      </Row>
    </>
  );
}
