import { montanaCountyNames } from "./countyNames";
import { Form, Button } from "react-bootstrap";

function ParcelSearch({
  setOwnerName,
  countyName,
  setCountyName,
  stateName,
  setStateName,
  handleSubmit,
  searchModalOpen,
}) {

  return (
    <div className={`w-100 h-100 bg-primary position absolute z-5 ${searchModalOpen ? "d-flex" : "d-none"} justify-content-center`}>
      <div className={`w-75 d-flex flex-column text-white d-flex justify-content-center align-items-center`}>
        <h2 className="text-secondary fw-bolder mb-4 fs-3">PROPERTY SEARCH</h2>
        <Form.Group className="w-100 mb-3" >
          <Form.Label className="d-block text-center fs-5 form-label fw-medium">State</Form.Label>
          <Form.Control style={{color: '#a0a0a0'}} as="select" value={stateName} disabled onChange={(event) => setStateName(event.target.value)}>
            <option value={"Montana"}>Montana</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="w-100 mt-2 mb-3">
          <Form.Label className="d-block text-center fs-5 form-label fw-medium">County</Form.Label>
          <Form.Control className="form-select" as="select" value={countyName} onChange={(event) => setCountyName(event.target.value)}>
            {montanaCountyNames.map((county) => (
              <option key={county} value={county}>
                {county}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="w-100 mt-2 mb-3">
          <Form.Label className="d-block text-center fs-5 form-label fw-medium">Owner Name (Optional)</Form.Label>
          <Form.Control type="text" onChange={(event) => setOwnerName(event.target.value)} />
        </Form.Group>
        <Button className="mt-3 px-5 btn btn-outline-secondary" 
          onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
}
export default ParcelSearch;
