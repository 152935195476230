export const excludedParcelOwners = [
    "MONTANA STATE OF",
    "STATE OF MONTANA",
    "USA",
    "U S A",
    "UNITED STATES OF AMERICA",
    "USDI NATIONAL PARK SERVICE",
    "NATIONAL PARK SERVICE",
    "STATE OF MONTANA DNRC",
    "USDI BUREAU OF LAND MANAGEMENT",
    "USDI BUREAU OF LAND MGMT (RESERVED)",
    "BUREAU OF LAND MANAGEMENT",
    "USDA FORST SERVICE",
    "USDA FOREST SERVICE",
    "USDA FOREST SERIVCE",
    "USDA FOREST SERVIECE",
    "USDI FISH AND WILDLIFE SERVICE",
    "MONTANA FISH WILDLIFE & PARKS",
    "USDI FISH & WILDLIFE SERVICE",
    "STATE OF MONTANA STATE BOARD OF LAND COMMISSIONERS",
    "USA IN TRUST",
    "USA IN TRUST FOR",
    "THE BLACKFEET TRIBE OF THE BLACKFEET INDIAN RESERVATION",
    "BLACKFEET TRIBE OF THE BLACKFEET INDIAN RESERVATION",
    "CONFEDERATED SALISH & KOOTENAI TRIBES",
    "FORT PECK INDIAN RESERVATION",
    "USA IN TRUST FOR NORTHERN CHEYENNE TRIBE",
    "USA IN TRUST FOR CROW TRIBE",
    "DNRC",
]