import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';

import ListingService from '../../services/listing.service';

import { SearchListings } from '../../components/forms/searchPublicListings';
import { listingTypesDefinitions } from '../../listingTypes/listingTypes';
import { statesList } from "../../components/data/statesList";

import { Col, Row, Button, Container, Card } from 'react-bootstrap';

import PublicFooter from '../../components/PublicFooter';
import Preloader from '../../components/Preloader';


const Search = (props) => {

  const [queryStatusListings, setQueryStatusListings] = useState(false);
  const [unfilteredListingsList, setUnfilteredListingsList] = useState();
  const [listingsList, setListingsList] = useState([]);
  useEffect(() => {
    document.title = "Search Listings | REC-X";

    if ( !queryStatusListings ) {
      ListingService.getListingsPublic()
        .then(res => {
          setQueryStatusListings(res.status);
          setListingsList(res.body);
          setUnfilteredListingsList(res.body)
        });
      }
  }, []);


  let searchListingsFormData = {};
  let parsedHash = queryString.parse(window.location.hash);
  if ( parsedHash && Object.keys(parsedHash).length > 0 ) {
    // Filter out all falsy values ( "", 0, false, null, undefined )
    searchListingsFormData = Object.entries(parsedHash).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
  }
  // if only 1 'activity' parsed from URL, convert to an array with that single value
  if ( typeof searchListingsFormData.activity === 'string' ) {
    const strVal = searchListingsFormData.activity;
    searchListingsFormData.activity = [];
    searchListingsFormData.activity.push(strVal);
  }

  // Run searchListings algo if hash parameters included in URL
  useEffect(() => {
    if ( parsedHash && Object.keys(parsedHash).length > 0 ) searchListings();
  }, [unfilteredListingsList]);

  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData); // Stringify form array into hash parmeters
    window.location.hash = queryHash; // Update search page URL

    let filteredListingsList = [];

    if ( Object.keys(searchListingsFormData).length > 0 ) {

      // Handle 'activity' checkbox filters
      Object.keys(searchListingsFormData).some(searchTerm => {
        if ( searchTerm === 'activity' ) {
          _.filter(unfilteredListingsList, function(listing) {
            if ( listing.listingType && Object.keys(listing.listingType).length > 0 ) {
              let listing_type = listing.listingType;
              // Check if arrays match for least one 'activity'
              if ( Object.keys(_.intersectionWith(searchListingsFormData.activity, listing_type, _.isEqual)).length ) {
                // thisSearchMatches used to display 'activity' matche(s)
                listing.thisSearchMatches = _.intersectionWith(searchListingsFormData.activity, listing_type, _.isEqual);
                // thisSearchRanking used to sort strongest 'activity' matches to top
                listing.thisSearchRanking = Object.keys(listing.thisSearchMatches).length;
                filteredListingsList.push(listing);
              }
            }
          });
        }

        filteredListingsList = _.orderBy(filteredListingsList, ['thisSearchRanking'], ['desc']); // Sort multiple 'activity' matches to top using thisSearchRanking
        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        setListingsList(filteredListingsList);
        return null;
      });

      // Handle State filter
      if ( searchListingsFormData.state && Object.values(statesList).includes(searchListingsFormData.state) ) { // restrict to valid states
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {
          return ( listing.state === undefined || listing.state !== searchListingsFormData.state );
        });

        filteredListingsList = _.uniq(listingList); // Remove duplicates
        setListingsList(filteredListingsList);
      } else { // remove invalid state value
        delete searchListingsFormData.state;
      }

      // Handle Date Range filter
      if ( searchListingsFormData.start && searchListingsFormData.end ) {
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {

          let rejectListing = false;
          for (var m = moment(searchListingsFormData.start); m.diff(searchListingsFormData.end, 'days') <= 0; m.add(1, 'days')) {

            if ( listing.availableBookingDates.includes(m.format('YYYY-MM-DD')) ) {
              rejectListing = false;
              break;
            } else {
              rejectListing = true;
            }
          }

          return rejectListing;

        });

        filteredListingsList = _.uniq(listingList); // Remove duplicates
        setListingsList(filteredListingsList);
      }

      // Handle text search term
      if ( searchListingsFormData.searchText ) {
        _.filter(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {
          let selected = false; // prevent multiple positive hits on same a single listing object
          Object.values(listing).map(function(dataPoint) {
            if ( typeof dataPoint === 'string' ) {
              dataPoint = dataPoint.toLowerCase();
              if ( selected !== true && dataPoint.includes(searchListingsFormData.searchText.toLowerCase()) ) {
                filteredListingsList.push(listing);
                selected = true;
              }
            }
            return null;
          });
        });

        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        setListingsList(filteredListingsList);
      }

    } else { // If search parameters are removed, remove previous filters w/o requiring page refresh
      clearSearch();
    }
  }

  const clearSearch = () => {
    searchListingsFormData = {};
    setListingsList(unfilteredListingsList);
    window.history.replaceState(null, null, '#');
  }

  return (
    <>
      <section className="section-header pt-5 pt-lg-6 pb-3 pb-lg-5 bg-primary text-white" id="home">
        <Container>

          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block"><span className="flaticon-fishing"></span></div>
              <h1 className="fw-bolder text-secondary">Search REC-X Listings</h1>
              <p className="text-muted fw-light mb-5 h5">The Marketplace for Unique Recreational Experiences</p>
            </Col>
          </Row>

          <SearchListings listingTypesDefinitions={listingTypesDefinitions} formData={searchListingsFormData} searchListings={searchListings} clearSearch={clearSearch} />

          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>

        </Container>
      </section>

      <Row className="g-0">
        <Col id="searchResults" className="px-4 my-4">
          {(queryStatusListings !== 200)
            ?
              <Preloader message="Loading..." logoSize={50} show={true} />
            :
              <>
                <Row className="mb-5">
                  <Col className="text-center">
                    {listingsList.length} Listings Found
                  </Col>
                </Row>
                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                  {
                    listingsList.map(function (listing) {

                      let primaryImage = ( listing.hasOwnProperty('gallery') && listing.gallery.length ) 
                        ? listing.gallery[0] 
                        : `https://picsum.photos/200/100?${Math.floor(Math.random()*100)}`;

                      return (
                        <Col className="mb-4" key={listing._id}>
                          <Link to={"/listing/"+listing._id}>
                            <Card border="light" className="bg-white shadow-sm h-100 overflow-hidden">
                                <Card.Title className="text-center py-5 px-3 mb-0" style={{backgroundImage: `url(${primaryImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                  <h5 className="fw-bolder mb-0" style={{textShadow: '0 0 3px #fff'}}>{listing.title}</h5>
                                </Card.Title>
                                <Card.Body className="p-3">
                                  {listing.thisSearchMatches &&
                                    <div className="activities badges mb-1">
                                      {
                                        Object.values(listing.thisSearchMatches).map(function (activity) {
                                          return (
                                            <span className="activity badge bg-secondary py-1 m-1" key={activity}>{listingTypesDefinitions[activity].type.title}</span>
                                          );
                                        })
                                      }
                                    </div>
                                  }
                                  {listing.description}
                                </Card.Body>
                                <Card.Footer className="d-grid border-top-0 p-0">
                                  <Button /*as={Link} to={"/listing/"+listing._id}*/ variant="secondary" className="border-radius-0">View Details</Button>
                                </Card.Footer>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })
                  }
                </Row>
              </>
          }
        </Col>
      </Row>

      <PublicFooter />

    </>
  );
};
export default Search;
