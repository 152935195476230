import bigGameHunting from "./big-game-hunting.json";
import varmintHunting from "./varmint-hunting.json";
import birdHunting from "./bird-hunting.json";
import fishing from "./fishing.json";
import camping from "./camping.json";

import { faDrumstickBite, faFrog, faCrow, faFish, faCampground } from "@fortawesome/free-solid-svg-icons";


export const listingTypesDefinitions = {
	'bigGameHunting': bigGameHunting,
	'varmintHunting': varmintHunting,
	'birdHunting': birdHunting,
	'fishing': fishing,
	'camping': camping
};


export const listingTypesIcons = {
	'bigGameHunting': faDrumstickBite,
	'varmintHunting': faFrog,
	'birdHunting': faCrow,
	'fishing': faFish,
	'camping':  faCampground
}
