import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Cookies from "js-cookie";

import { Form, Button } from "react-bootstrap";

import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import MapTooltip from "./MapTooltip";

import ListingService from "../../services/listing.service";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCampground, faMapMarker, faFish, faCrosshairs, faHiking, faBicycle } from '@fortawesome/free-solid-svg-icons';

import locationDot from '../../assets/img/map-icons/location-dot.svg';
import campground from '../../assets/img/map-icons/campground.svg';
import bicycle from '../../assets/img/map-icons/bicycle.svg';
import hiking from '../../assets/img/map-icons/hiking.svg';
import fish from '../../assets/img/map-icons/fish.svg';
import hunting from '../../assets/img/map-icons/crosshairs.svg';
import { min } from "moment-timezone";

const DrawingTools = (props) => {
  const history = useHistory();

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const [listingName, setListingName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const updateTimeoutRef = useRef(null)

  const mapIcons = [
    { icon: faCampground, text: 'Campground', svg: campground, name: 'campground'},
    { icon: faHiking, text: 'Hiking', svg: hiking, name: 'hiking'},
    { icon: faFish, text: 'Fishing', svg: fish, name: 'fish'},
    { icon: faCrosshairs, text: 'Hunting', svg: hunting, name: 'hunting'},
    { icon: faBicycle, text: 'Bicycle', svg: bicycle, name: 'bicycle'},
    { icon: faMapMarker, text: 'Marker', svg: locationDot, name: 'location-dot'},
  ];

  useEffect(() => {
    if (props.editingListing) props.setPropertyFeatures(props.listingData.map_geoJSON)
  }, [])

  const [listingData, setListingData] = useState({});
  let { id } = useParams();

  useEffect(() => {
    if (props.thisPropertyData) {    
      setListingData({
        'title': listingName,
        'property': props.thisPropertyData._id,
        'host': props.thisPropertyData.host,
        'map_geoJSON': props.propertyFeatures,
        'address': props.thisPropertyData.address
      });
    }
  }, [props, listingName, props.propertyFeatures])

  const handleChangeColor = function (color, featureID) {
    const currentPropertyFeatures = props.propertyFeaturesRef.current
    const feature = currentPropertyFeatures.features.find(feature => feature.id === featureID)

    switch (color.toLowerCase()) {
      case 'red':
        props.setDrawingColor('#ff0000')
        break
    case 'green':
        props.setDrawingColor('#00ff00')
        break
    case 'blue':
        props.setDrawingColor('#0000ff')
        break
    case 'yellow':
        props.setDrawingColor('#ffff00')
        break
    case 'orange':
        props.setDrawingColor('#ffa500')
        break
    case 'purple':
        props.setDrawingColor('#800080')
        break
    }

    fetch(feature.properties.icon)
    .then(response => response.text())
    .then(svgContent => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        svgElement.setAttribute('fill', props.drawingColorRef.current || '#00ffff');
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const el = document.getElementById(feature.id);
        if (el) el.style.backgroundImage = `url('data:image/svg+xml;base64,${btoa(svgString)}')`;
    });
  }
  window.handleChangeColor = handleChangeColor;

  useEffect(() => {
    if (!props.map.current) return;

    library.add(faCampground, faMapMarker, faFish, faCrosshairs, faHiking, faBicycle);
    dom.watch();

    props.map.current.getSource('markers') && 
      props.map.current.removeSource('markers');

    props.map.current.isStyleLoaded() && 
      props.map.current.addSource('markers', { type: 'geojson', data: props.propertyFeatures });

    const addMarker = function (e) {
      if (!props.isAddingMarker) return
      
      props.setIsAddingMarker(false); 

      const markerId = `${Math.floor(Math.random() * 1000000000)}`;
      const labelId = `label-${markerId}`;
      const markerCoordinates = [e.lngLat.lng, e.lngLat.lat];
      
      let newMarker = {
        type: 'Feature',
        id: markerId,
        geometry: {
          type: 'Point',
          coordinates: markerCoordinates,
        },
        properties: {
          icon: props.markerIconRef.current,
          label: 'Click to add label...',
          type: 'marker',
        },
      };

      props.map.current.getLayer(labelId) &&
        props.map.current.removeLayer(labelId)

      props.map.current.getSource(labelId) &&
        props.map.current.removeSource(labelId);
      
      props.map.current.addSource(labelId, {
        type: 'geojson',
        data: newMarker
      });

      props.map.current.addLayer({
        id: labelId,
        type: 'symbol',
        source: labelId,
        layout: {
          'text-field': ['get', 'label'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 1.5,
          'text-justify': 'auto',
        },
        minzoom: 10,
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': '#000000',
          'text-halo-width': 2,
        }
      });

      function updateMarkerData() {
        const updatedFeatures = {
          ...props.propertyFeaturesRef.current,
          features: [...props.propertyFeaturesRef.current.features, newMarker]
        };
        props.setPropertyFeatures(updatedFeatures);
      }
      updateMarkerData()

      props.markerIconRef.current = props.markerIcon
      const el = document.createElement('div');  
      el.className = 'marker';
      el.id = markerId;
      el.style.cssText = `
        background-image: url('${props.markerIconRef.current}');
        width: 40px;
        height: 40px;
        background-size: fit;
        background-repeat: no-repeat;
        cursor: pointer;
      `
      
      const marker = new mapboxgl.Marker(el, { draggable: true})
        .setLngLat(newMarker.geometry.coordinates)
        .addTo(props.map.current)

      marker.id = markerId;

      const handleNewMarkerPopup = () => {
        let popup = props.addPopup(markerId, newMarker.geometry.coordinates)
        marker.setPopup(popup)
      }

      el.addEventListener('click', handleNewMarkerPopup)
      el.addEventListener('touchend', handleNewMarkerPopup) 

      marker.on('dragend', () => {
        newMarker.geometry.coordinates = marker.getLngLat().toArray()
        const labelSource = props.map.current.getSource(`label-${markerId}`)
        labelSource && labelSource.setData(newMarker)

        updateMarkerData()
      })
    }

    const onCreate = function (e) {
      let newFeature = e.features[0];
      newFeature.properties.type = 'drawn-feature';
      let updatedFeatures = props.propertyFeatures;

      let featureCoordinates = turf.centroid(e.features[0]).geometry.coordinates;
      const labelId = `label-${newFeature.id}`;

      props.map.current.getSource(labelId) &&
        props.map.current.removeSource(labelId);
      props.map.current.addSource(labelId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: featureCoordinates,
          },
          properties: {
            label: 'Click to add label...'
          }
        }
      });

      props.map.current.getLayer(labelId) &&
        props.map.current.removeLayer(labelId)
      props.map.current.addLayer({
        id: labelId,
        type: 'symbol',
        source: labelId,
        layout: {
          'text-field': ['get', 'label'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 1.5,
          'text-justify': 'auto',
        },
        minzoom: 10,
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': '#000000',
          'text-halo-width': 2,
        }
      });

      if (!updatedFeatures.features.find(f => f.id === newFeature.id)) {
        updatedFeatures.features.push(newFeature);
        props.setPropertyFeatures(updatedFeatures);
      }
    }

    const onUpdate = function (e) {
      const popUps = document.getElementsByClassName('mapboxgl-popup');
      if (popUps && popUps.length > 0) {
        popUps[0].remove();
      }
      let updatedFeature = e.features[0];
      let updatedFeatures = props.propertyFeatures;
      let index = props.propertyFeatures.features.findIndex(f => f.id === updatedFeature.id);

      if (index !== -1) {
        const existingLabel = updatedFeatures.features[index].properties?.label;
        if (existingLabel && existingLabel !== 'Click to add label...') updatedFeature.properties.label = existingLabel;

        updatedFeatures.features[index] = updatedFeature
        if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current)

        updateTimeoutRef.current = setTimeout(() => {
          props.setPropertyFeatures(updatedFeatures)

          const newCentroid = turf.centroid(updatedFeature).geometry.coordinates;
          const labelId = `label-${updatedFeature.id}`

          const updatedGeoJSON = {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: newCentroid,
              },
              properties: {
                label: updatedFeature.properties.label === "Click to add label..." ? "" : updatedFeature.properties.label,
              }
            }
          }

          if (props.map.current.getSource(labelId)) {
            props.map.current.getSource(labelId).setData(updatedGeoJSON.data);
          } else {
            props.map.current.getLayer(labelId) &&
              props.map.current.removeLayer(labelId)
            props.map.current.addSource(labelId, updatedGeoJSON);
            props.map.current.addLayer({
              id: labelId,
              type: 'symbol',
              source: labelId,
              layout: {
                'text-field': ['get', 'label'],
                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                'text-radial-offset': 0.5,
                'text-justify': 'auto',
              },
              minzoom: 10,
              paint: {
                'text-color': '#000',
                'text-halo-color': '#fff',
                'text-halo-width': 2,
              }
            });
          }
        }, 100)
      }
    }

    const onDelete = function (e) {
      let deletedFeature = e.features[0];
      let updatedFeatures = props.propertyFeatures;
      let featureIndex = props.propertyFeatures.features.findIndex(f => f.id === deletedFeature.id);
      
      if (featureIndex !== -1) {
        updatedFeatures.features.splice(featureIndex, 1);
        props.setPropertyFeatures(updatedFeatures)

        const labelId = `label-${deletedFeature.id}`;

        props.map.current.getLayer(labelId) &&
          props.map.current.removeLayer(labelId)

        props.map.current.getSource(labelId) &&
          props.map.current.removeSource(labelId);
      }
    }

    props.map.current.on("draw.create", onCreate)
    props.map.current.on("draw.update", onUpdate)
    props.map.current.on("draw.delete", onDelete)
    props.map.current.on('click', addMarker)
    props.map.current.on('touchend', addMarker)

    return () => {
      props.map.current.off("draw.create", onCreate)
      props.map.current.off("draw.create", onUpdate)
      props.map.current.off("draw.create", onDelete)
      props.map.current.off('click', addMarker)
      props.map.current.off('touchend', addMarker)
    }
  })

  async function saveMapFeatures() {
    if (props.addingListing) createListing()
    if (props.editingListing) editListing()

    function createListing() {
      ListingService.addListing(listingData, authToken)
      .then(res => {
        history.push(`/dashboard/listing/${res.data._id}`)
      })
      .catch(err => {
        // console.error("Error creating listing", err);
      });
    }

    function editListing() {
      ListingService.updateListing(id, { map_geoJSON: props.propertyFeatures }, authToken)  
        .then(res => {
          window.location.reload()
        })
        .catch(err => {
          // console.err("Error updating listing", err);
        })
    }
  }

  useEffect(() => {
    if (!props.map.current) return

    if (props.map.current.getLayer('county-parcels-line')) {
      const hiddenLayers = [
        props.parcelsFillId,
        props.parcelsLineId,
        props.highlightedParcelsLineId
      ]
      hiddenLayers.forEach(layer => {
        props.map.current.setLayoutProperty(
          layer,
          "visibility",
          "none"
        )
      })
    }
  }, [props])

  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitted(true)
    if (listingName !== "") saveMapFeatures()
  }

  return (
    <div id="drawing-tools">
      {props.isAddingMarker && (
        <div id="marker-icons-grid" className="position-absolute right-2 rounded top-50 z-3 bg-white py-1" >
          <div className="row g-1 px-1">
            {mapIcons.map((icon, index) => (
              <div key={index} className="col-4 d-flex justify-content-center">
                <Button className="m-0 p-2 d-flex ratio ratio-1x1 justify-content-center" onClick={() => props.setMarkerIcon(icon.svg)}>
                  <FontAwesomeIcon icon={icon.icon} className="w-100 p-2"/>
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`bg-primary text-white h-100 w-100 d-flex justify-content-center z-5 position-absolute ${modalOpen ? "d-block" : "d-none"}`} >
        <div className="bg-primary text-white h-100 w-100 d-flex justify-content-center align-items-center flex-column text-center">
          <h2 className="text-secondary fw-bolder mb-4 fs-5 w-75">
            Please provide a name for this listing.
          </h2>
          <Form noValidate onSubmit={handleSubmit} className="w-50 mt-2">
            <Form.Group>
              <Form.Control
                className="text-center"
                placeholder={"e.g. Camping at Silver Creek"}
                onChange={(e) => {
                  setListingName(e.target.value)
                }}                  
                required
                isInvalid={submitted && listingName === ''}
            />
            <Form.Control.Feedback type="invalid">
                Please enter a listing name.
            </Form.Control.Feedback>
            </Form.Group>
            <Button className="my-3 px-5 btn btn-outline-secondary"
              type="submit"
              variant="primary"
            >
              Submit
            </Button>
          </Form>
          <div className="text-muted fs-6 w-75">
            Click submit to save your listing.
          </div>
        </div>
      </div>
      <div className="bg-white rounded-2 h-auto mb-5 me-4 position-absolute right-0 bottom-0 z-3">
        <div className="d-flex justify-content-center">
          <Button
            className={`btn btn-outline-primary fw-medium`}
            variant="outline-primary"
            onClick={() => {
              props.editingListing ? saveMapFeatures() : setModalOpen(true)}
            }
          >
            Save Map
          </Button>
        </div>
      </div>

      {props.drawingMode === 'simple_select' && !props.isAddingMarker && <MapTooltip text={"Use the drawing toolbar on the right to add map features."}/>} 
      {props.drawingMode === 'draw_point' && <MapTooltip text={"Click to place points, or drag a point to a new location."}/>}  
      {props.drawingMode === 'draw_line_string' &&  <MapTooltip text={"Click to draw line vertices then double click to stop drawing. Drag existing lines or vertices to change their location."}/>}
      {props.drawingMode === 'draw_polygon' && <MapTooltip text={"Click to draw polygon corners then double click to stop drawing. Drag existing shapes or corners to change their location."}/>}
      {props.isAddingMarker && <MapTooltip text={"Select an icon to mark common locations like campgrounds and hiking trails."}/>}
      
    </div>
  );
}

export default DrawingTools;
