import bigGameHunting from "./big-game-hunting.json";
import varmintHunting from "./varmint-hunting.json";
import birdHunting from "./bird-hunting.json";
import fishing from "./fishing.json";
import camping from "./camping.json";

import { faDrumstickBite, faFrog, faCrow, faFish, faCampground, faBaseballBall, faTree, faTruckMonster, faMountain } from "@fortawesome/free-solid-svg-icons";


export const masterCategoryDefinitions = [
	{
		'id': 'hunting',
		'title': 'Huting',
		'icon': faDrumstickBite,
		'listingTypes': ['bigGameHunting', 'varmintHunting', 'birdHunting']
	},
	{
		'id': 'fishing',
		'title': 'Fishing',
		'icon': faFish,
		'listingTypes': ['fishing']
	},
	{
		'id': 'campingRVing',
		'title': 'Camping & RV\'ing',
		'icon': faCampground,
		'listingTypes': ['camping']
	},
	{
		'id': 'natureActivities',
		'title': 'Nature Activities',
		'icon': faFrog,
		'listingTypes': ['hiking', 'photography', 'wildlifeViewing', 'farmTours', 'watersports', 'horsebackRiding', 'rockClimbing', 'snowshoeing', 'orienteering', 'foraging', 'shedHunting']
	},
	{
		'id': 'sportsExercise',
		'title': 'Sports Exercise',
		'icon': faBaseballBall,
		'listingTypes': ['targetShooting', 'snowsports', 'gliding', 'diving', 'discGolf', 'golf', 'racketSports', 'airsoftPainball', 'running', 'cycling', 'yoga']
	},
	{
		'id': 'events',
		'title': 'Events',
		'icon': faTree,
		'listingTypes': ['events']
	},
	{
		'id': 'offRoadAdventures',
		'title': 'Off-Road Adventures',
		'icon': faTruckMonster,
		'listingTypes': ['off-roading']
	},
	{
		'id': 'generalRecreation',
		'title': 'General Recreation',
		'icon': faMountain,
		'listingTypes': []
	}
];


export const categoriesIcons = {
	'hunting': faDrumstickBite,
	'fishing': faFish,
	'camping':  faCampground,
	'natureActivities': faFrog,
	'sportsExercise': faDrumstickBite,
	'events': faDrumstickBite,
	'offRoadAdventures': faTruckMonster,
	'generalRecreation': faDrumstickBite
};


export const listingTypesDefinitions = {
	'bigGameHunting': bigGameHunting,
	'varmintHunting': varmintHunting,
	'birdHunting': birdHunting,
	'fishing': fishing,
	'camping': camping
};


export const listingTypesIcons = {
	'bigGameHunting': faDrumstickBite,
	'varmintHunting': faFrog,
	'birdHunting': faCrow,
	'fishing': faFish,
	'camping':  faCampground
};
