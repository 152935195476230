import { useState, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, Button } from "react-bootstrap";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { NavigationControl, FullscreenControl } from 'mapbox-gl';
import * as turf from "@turf/turf";
import proj4 from "proj4";
import { montanaCountyNames } from "./countyNames"
import { mapStyles } from "./mapStyles";

import campground from '../../assets/img/map-icons/campground.svg';

import DrawingTools from "./DrawingTools";
import ClaimParcels from "./ClaimParcels";
import ParcelSearch from "./ParcelSearch";

import { excludedParcelOwners } from "./excludedParcelOwners";

const Map = (props) => {
  const addingProperty = props.mapMode === "addProperty" ? true : false;
  const addingListing = props.mapMode === "addListing" ? true : false;
  const editingListing = props.mapMode === "editListing" ? true : false;
  const isDrawing = addingListing || editingListing ? true : false;

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef(null)
  const map = useRef(null)
  const draw = useRef(null)
  const [zoom, setZoom] = useState(6)
  const [stateName, setStateName] = useState("Montana")
  const [countyName, setCountyName] = useState("Beaverhead")
  const [ownerName, setOwnerName] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [propertyName, setPropertyName] = useState("")
  const [propertyCentroid, setPropertyCentroid] = useState(null)
  const [propertyBbox, setPropertyBbox] = useState(null)
  const [ownershipConfirmed, setOwnershipConfirmed] = useState(false)
  const [areParcelsClaimed, setParcelsClaimed] = useState(false)
  const [claimedParcels, setClaimedParcels] = useState([])
  const [searchModalOpen, setSearchModalOpen] = useState(true)
  const [claimModalOpen, setClaimModalOpen] = useState(false)
  const [isAddingMarker, setIsAddingMarker] = useState(false)

  const [drawingColor, setDrawingColor] = useState("#ff0000");
  const drawingColorRef = useRef(drawingColor);
  const [markerIcon, setMarkerIcon] = useState(campground);
  const markerIconRef = useRef()

  const [propertyFeatures, setPropertyFeatures] = useState({
    "type": "FeatureCollection",
    "features": []
  })
  const propertyFeaturesRef = useRef(propertyFeatures);

  const parcelsSourceId = "montana-parcels";
  const parcelsFillId = `${parcelsSourceId}-fill`;
  const parcelsLineId = `${parcelsSourceId}-line`;
  const highlightedParcelsLineId = `${parcelsSourceId}-highlighted`;
  const mapLayerId = "mapbox/satellite-streets-v12";

  let parcelsOwnedByOwner = []
  let storedParcels = [];

  useEffect(() => {
    propertyFeaturesRef.current = propertyFeatures
  }, [propertyFeatures, propertyFeaturesRef])

  // Load map
  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/${mapLayerId}`,
        center: [-110.3626, 46.8797],
        zoom: zoom,
      });

      map.current.on("load", function () {
        addMapControls();
        
        if (addingProperty || addingListing) loadParcels();
        if (editingListing) loadSavedFeatures()
        
        let clickTimeout = null;
        let isDragging = false;
        
        function handleMapClick(e) {
          if (isDragging || isDrawing) {
            isDragging = false;
            return;
          }
          const features = e.features ? [...e.features] : [];
          clearTimeout(clickTimeout);
          clickTimeout = setTimeout(() => {
            if (features.length > 0) {
              let city, state, zip, stateZip
              const { CityStateZ, PARCELID, OwnerName, AddressLin, CountyName } = features[0].properties;

              if (CityStateZ) {
                [city, stateZip] = CityStateZ.split(', ');
                [state, zip] = stateZip.split(' ');
              }

              const parcel = {
                parcelID: PARCELID,
                ownerName: OwnerName,
                street_address: AddressLin,
                county: CountyName,
                city,
                state,
                zip,
              }

              handleParcelClick(parcel);
            }
          }, 200);
        }
        map.current.on("touchstart", () => isDragging = false);
        map.current.on("touchmove", () => isDragging = true);

        map.current.on("click", 'county-parcels-fill', handleMapClick);
        map.current.on("touchend", 'county-parcels-fill', handleMapClick);
        map.current.on("dblclick", "county-parcels-fill", () => clearTimeout(clickTimeout));
      });

      map.current.on("zoomend", function () {
        let zoomLevel = map.current.getZoom();
        if (addingListing) setZoom(zoomLevel);
      });
    }
  });

  useEffect(() => {
    if (map.current) {
      map.current.setStyle(`mapbox://styles/${mapLayerId}`);
      map.current.on("style.load", function () {
        loadParcels();
      });
    }
  }, [mapLayerId]);
    

  function loadParcels() {
    if (!map.current) return;

    if (!map.current.getSource(parcelsSourceId)) {
      map.current.addSource(parcelsSourceId, {
        type: "vector",
        url: process.env.REACT_APP_MAPBOX_TILESET_URL,
      })

      const handleSourceData = function (e) {
        if (e.sourceId === parcelsSourceId && map.current.isSourceLoaded(parcelsSourceId)) {
          map.current.off("sourcedata", handleSourceData)
          map.current.getLayer(highlightedParcelsLineId)
            && map.current.removeLayer(highlightedParcelsLineId)

          const excludedOwnersFilter = excludedParcelOwners.reduce((filter, ownerName) => {
            return [...filter, ["!=", "OwnerName", ownerName]];
          }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"]]);

          if (addingProperty) {
            map.current.getLayer(parcelsFillId) &&
              map.current.removeLayer(parcelsFillId);
            map.current.addLayer({
              id: parcelsFillId,
              type: "fill",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "fill-color": "#00ffff",
                "fill-opacity": 0.5,
              },
              minzoom: 8,
              filter: excludedOwnersFilter,
              });

            map.current.getLayer("parcel-results-line") &&
              map.current.removeLayer("parcel-results-line");
            map.current.addLayer({
              id: "parcel-results-line",
              type: "line",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "line-color": "#ffffff",
                "line-width": 1, 
                "line-opacity": 1, 
              },
              minzoom: 8,
              filter: excludedOwnersFilter,
              });

            map.current.getLayer("county-parcels-fill") &&
              map.current.removeLayer("county-parcels-fill");
            map.current.addLayer({
              id: "county-parcels-fill",
              type: "fill",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "fill-color": "#ffffff",
                "fill-opacity": 0,
              },
              minzoom: 8,
              filter: ["==", "CountyName", countyName],
            });

            map.current.getLayer(highlightedParcelsLineId) &&
              map.current.removeLayer(highlightedParcelsLineId);
            map.current.addLayer({
              source: parcelsSourceId,
              id: highlightedParcelsLineId,
              "source-layer": "montana-parcels",
              type: "fill",
              paint: {
                "fill-color": "#ff00ff",
                "fill-opacity": 0.65,
              },
              filter: [
                "in",
                "PARCELID",
                ...storedParcels.map((parcel) => parcel.parcelID),
              ],
            });

            map.current.getLayer("county-parcels-line") &&
              map.current.removeLayer("county-parcels-line");
            map.current.addLayer({
              id: "county-parcels-line",
              type: "line",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "line-color": "#ffffff",
                "line-width": 1, 
                "line-opacity": 0.8, 
              },
              minzoom: 8,
              filter: ["==", "CountyName", countyName],
            });

          } else if (isDrawing) {
            let thisPropertyParcels
            addingListing ? thisPropertyParcels = props.thisPropertyData.parcels : thisPropertyParcels = props.listingData.propertyData.parcels
        
            map.current.getLayer(highlightedParcelsLineId) &&
              map.current.removeLayer(highlightedParcelsLineId);
            map.current.addLayer({
              source: parcelsSourceId,
              id: highlightedParcelsLineId,
              "source-layer": "montana-parcels",
              type: "line",
              paint: {
                "line-color": "#ff00ff",
                "line-opacity": 0.9,
                "line-width": 3,
              },
              filter: [
                "in",
                "PARCELID",
                ...thisPropertyParcels
              ],
            });
        
            fetch("/data/MT_centroids.geojson")
            .then(response => response.json())
            .then((data) => {
              let centroid = data.features.find(
                f => f.properties.NAME === props.thisPropertyData?.address?.county?.toUpperCase())?.geometry.coordinates;

              if (centroid && map.current) {
                map.current.fitBounds(props.thisPropertyData.property_bbox, { padding: 80 }) 
              }
            });  
          }
        }
      }
      map.current.on("sourcedata", handleSourceData)
    }
  }

  const handleParcelClick = (parcel) => {
    const clickedParcelId = parcel.parcelID || parcel.attributes.PARCELID
    setClaimedParcels((prevState) => {
      if (prevState.some((existingParcel) => existingParcel.parcelID === clickedParcelId)) {
        let updatedParcels = prevState.filter(
          (existingParcel) => existingParcel.parcelID !== parcel.parcelID
        );
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID);

        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }
        return updatedParcels;
      } else {
        let updatedParcels = [
          ...prevState,
          parcel
        ];
        
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID || parcel.attributes.PARCELID);
        
        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }

        return updatedParcels;
      }
    });
  };

  function loadSavedFeatures() {
    map.current.getLayer('property-line') 
    && map.current.removeLayer('property-line');

    !map.current.getSource('montana-parcels') 
      && map.current.addSource('montana-parcels', {
          type: "vector",
          url: process.env.REACT_APP_MAPBOX_TILESET_URL,
        }); 

    map.current.addLayer({
      source: 'montana-parcels',
      id: 'property-line',
      "source-layer": "montana-parcels",
      type: "line",
      paint: {
        "line-color": "#ff00ff",
        "line-opacity": 0.9,
        "line-width": 3,
      },
      filter: [
        "in",
        "PARCELID",
        ...props.listingData.propertyData.parcels
      ],
  });
    
    function loadMarkerIcons() {
      const addedMarkers = new Set();

      props.listingData.map_geoJSON.features.forEach((feature) => {
        if (feature.properties.type === 'drawn-feature') draw.current.add(feature)
        else if (feature.properties?.type === 'marker' && !addedMarkers.has(feature.id)) {
          function updateSVGColor(newColor) {
            fetch(feature.properties.icon)
            .then(response => response.text())
            .then(svgContent => {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
                const svgElement = svgDoc.documentElement;
                svgElement.setAttribute('fill', newColor || '#00ffff');
                const svgString = new XMLSerializer().serializeToString(svgElement);
                el.style.backgroundImage = `url('data:image/svg+xml;base64,${btoa(svgString)}')`;
            });
          }
          const el = document.createElement('div');  
          el.className = 'marker';
          el.id = feature.id;
          el.style.cssText = `
            width: 40px;
            height: 40px;
            background-size: fit;
            background-repeat: no-repeat;
            cursor: pointer;
          `
          updateSVGColor(feature.properties.color)

          const popupHTML = generatePopupHTML(feature.id)
          const popup = new mapboxgl.Popup()
            .setHTML(popupHTML)

          const marker = new mapboxgl.Marker(el, { draggable: true})
            .setLngLat(turf.centroid(feature).geometry.coordinates || feature.geometry.coordinates)
            .addTo(map.current)
            .setPopup(popup)
  
          addedMarkers.add(feature.id);
            
          popup.on('open', () => {
            const deleteBtn = document.getElementById(`deleteButton-${feature.id}`)
            const saveBtn = document.getElementById(`saveButton-${feature.id}`)
  
            // handle marker delete
            deleteBtn && deleteBtn.addEventListener('click', () => {
              handlePopupDelete(feature.id, popup)
              marker.remove()
            })
  
            // handle marker save
            saveBtn && saveBtn.addEventListener('click', () => {  
              handlePopupSave(feature.id, popup)
            })
        })
  
          marker.on('dragend', () => {
            const lngLat = marker.getLngLat();
            feature.geometry.coordinates = [lngLat.lng, lngLat.lat]
  
            const labelSource = map.current.getSource(`label-${feature.id}`)
            if (labelSource) labelSource.setData(feature)
          })
        }
      })
    }
    loadMarkerIcons()

    const bbox = turf.bbox(props.listingData.map_geoJSON);
    if (map.current && props.listingData.map_geoJSON.features.length > 0 ) map.current.fitBounds(bbox, { padding: 150, maxZoom: 13.5 });

    props.listingData.map_geoJSON.features.forEach((feature, index) => {
      const layerId = `feature-${feature.id}`;
      const labelId = `label-${feature.id}`;

      if (map.current.getLayer(layerId)) {
        map.current.removeLayer(layerId);
        map.current.removeSource(layerId);
      }

      if (feature.properties && feature.properties.label) {
        const labelCoordinates = turf.centroid(feature).geometry.coordinates;

        !map.current.getSource(labelId) &&
          map.current.addSource(labelId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: labelCoordinates,
              },
              properties: {
                label: feature.properties.label !== "Click to add label..." ? feature.properties.label : "",
              }
            }
          });
    
        !map.current.getLayer(labelId) &&
          map.current.addLayer({
            id: labelId,
            type: 'symbol',
            source: labelId,
            layout: {
              'text-field': ['get', 'label'],
              'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
              'text-radial-offset': 1.5,
              'text-justify': 'auto',
            },
            paint: {
              'text-color': '#ffffff', 
              'text-halo-color': '#000000', 
              'text-halo-width': 2, 
            }
          });
      }
    })  
  }

  const addMapControls = useCallback(() => {
    if (!map.current) return

    draw.current = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        point: (addingListing || editingListing),
        line_string: (addingListing || editingListing),
        polygon: (addingListing || editingListing),
      },
      styles: mapStyles
    });

    map.current.addControl(new FullscreenControl());
    map.current.addControl(new NavigationControl());

    if (isDrawing) {
      map.current.addControl(new MarkerIconControl());
      map.current.addControl(draw.current)
    }

  });

  class MarkerIconControl {
    onAdd() {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
      const button = document.createElement('button');
      button.className = 'mapbox-gl-draw_ctrl-draw-btn';
      button.id = 'marker-icon-control';
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M377 52c11-13.8 8.8-33.9-5-45s-33.9-8.8-45 5L288 60.8 249 12c-11-13.8-31.2-16-45-5s-16 31.2-5 45l48 60L12.3 405.4C4.3 415.4 0 427.7 0 440.4V464c0 26.5 21.5 48 48 48H288 528c26.5 0 48-21.5 48-48V440.4c0-12.7-4.3-25.1-12.3-35L329 112l48-60zM288 448H168.5L288 291.7 407.5 448H288z"/></svg>`;
      button.innerHTML = svg;
      this.container.appendChild(button);
      button.onclick = () => {
        setIsAddingMarker((prev) => !prev);
      }
      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }

  const handleDeleteParcel = (parcel) => {
    setClaimedParcels((prevState) => {
      if (prevState.some((existingParcel) => existingParcel.parcelID === parcel.parcelID)) {
        let updatedParcels = prevState.filter((existingParcel) => existingParcel.parcelID !== parcel.parcelID)
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID)

        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }
        return updatedParcels
      }
  })}

  useEffect(() => {
    if (areParcelsClaimed && ownershipConfirmed) {
      claimedParcels[0]?.ownerName &&
        setPropertyName(claimedParcels[0].ownerName)
      setClaimModalOpen(true)
    }
  }, [areParcelsClaimed, ownershipConfirmed, claimedParcels])

  const handleClaimParcels = () => {
    if (claimedParcels.length === 0) return

    let parcelIDs = claimedParcels.map(parcel => parcel.parcelID)
    let renderedFeatures = map.current.queryRenderedFeatures({ layers: ['county-parcels-line'] })
    let matchingFeatures = renderedFeatures.filter(function(feature) {
      return parcelIDs.includes(feature.properties.PARCELID)
    })
    let multiPolygon = turf.multiPolygon(matchingFeatures.map(feature => feature.geometry.coordinates))
    let centroid = turf.centroid(multiPolygon)
    let boundingBox = turf.bbox(multiPolygon)
    setPropertyCentroid(centroid.geometry.coordinates)
    setPropertyBbox(boundingBox)
    setParcelsClaimed(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSearchModalOpen(false)
    
    fetch(
      encodeURI(`https://services.arcgis.com/qnjIrwR8z5Izc0ij/arcgis/rest/services/Cadastral_Application_Support/FeatureServer/5/query?where=OwnerName LIKE '%${ownerName}%' AND CountyName='${countyName}'&outFields=CountyName,OwnerName,PARCELID&returnGeometry=true&returnZ=true&f=json`),
      {mode: 'cors'}
    )
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data.features)
        if (!data.features || data.features.length === 0) return

        const centroids = data.features.map((parcel) => {
          const polygon = turf.polygon(parcel.geometry.rings)
          const centroid = turf.centroid(polygon)
          return centroid
        })
  
        proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs')
        let bbox = turf.bbox(turf.featureCollection(centroids))
        bbox = [
          proj4('EPSG:3857', 'EPSG:4326', [bbox[0], bbox[1]]),
          proj4('EPSG:3857', 'EPSG:4326', [bbox[2], bbox[3]])
        ].flat()
              
        map.current.fitBounds(bbox, { padding: 80, maxZoom: 17 })
        
        let zoomAdjusted = false
        map.current.on('moveend', () => {
          if (!zoomAdjusted) {
            const currentZoom = map.current.getZoom()
            if (currentZoom < 9) {
              zoomAdjusted = true
              map.current.easeTo({ zoom: 9, duration: 1000 })
            }
          }
        })

        data.features.map((parcel) => {
          parcelsOwnedByOwner.push(parcel)
        })
        setParcelFilters()
      })

    if (ownerName === "") {
      fetch("data/MT_centroids.geojson")
        .then((response) => response.json())
        .then((data) => {
          var centroid
          for (let feature of data.features) {
            if (feature.properties.NAME === countyName.toUpperCase()) {
              centroid = feature.geometry.coordinates
              break
            }
          }
          if (centroid) {
            map.current &&
              map.current.jumpTo({
                center: [centroid[0], centroid[1]],
                zoom: 9,
              })
          } else {
            console.error(`County ${countyName} not found`)
          }
        })
    }
  }

  function setParcelFilters() {
    if (map.current && map.current.getLayer(parcelsFillId)) {
      const excludedOwnersFilter = excludedParcelOwners.reduce((filter, ownerName) => {
        return [...filter, ["!=", "OwnerName", ownerName]]
      }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"]])

      map.current.setFilter('county-parcels-line', excludedOwnersFilter)
      map.current.setFilter('county-parcels-fill', excludedOwnersFilter)
      map.current.setFilter('parcel-results-line', excludedOwnersFilter)
      map.current.getLayer(parcelsLineId) && map.current.setFilter(parcelsLineId, ["==", "CountyName", countyName])

      if (ownerName) {
        const parcelIDs = parcelsOwnedByOwner.map(parcel => parcel.attributes.PARCELID)
        const excludedOwnersFilter = excludedParcelOwners.reduce((filter, ownerName) => {
          return [...filter, ["!=", "OwnerName", ownerName]]
        }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"], ["in", "PARCELID", ...parcelIDs]])

        map.current.setFilter(parcelsFillId, excludedOwnersFilter)
        map.current.setFilter("parcel-results-line", excludedOwnersFilter)

        map.current.once('idle', function() {
          if (map.current.isSourceLoaded('montana-parcels')) {
            let sourceFeatures = map.current.querySourceFeatures('montana-parcels', { sourceLayer: 'montana-parcels' })
            sourceFeatures = sourceFeatures.filter(feature => {
              let parcelId = feature.properties.PARCELID
              if (parcelsOwnedByOwner.includes(parcelId)) return feature
            })

            if (sourceFeatures.length !== 0) {
                const featureCollection = turf.featureCollection(sourceFeatures) 
                const boundingBox = turf.bbox(featureCollection) 
                map.current.fitBounds(boundingBox, { padding: 50 })         
            }
          }
        })
      } else {
        map.current.setFilter(parcelsFillId, excludedOwnersFilter)
      }
    }
  }

  function getParcelById(parcelId) {
    return searchResults.find(parcel => parcel.attributes.PARCELID === parcelId)
  }

  function zoomToParcel(parcelID) {
    const parcel = getParcelById(parcelID) 
    const centroid = getCentroidByParcelId(parcelID)
    parcel && centroid && map.current.jumpTo({
      center: centroid,
      zoom: 14,
    })
  }

  function getCentroidByParcelId(parcelID) {
    const parcel = getParcelById(parcelID) 
    const polygon = {
      "type": "Polygon",
      "coordinates": parcel.geometry.rings
    }
    const centroid = proj4('EPSG:3857', 'EPSG:4326', turf.centroid(polygon).geometry.coordinates)
    return centroid
  }

  let hoverMarker = null
  function handleOnHover(parcelID) {
    const centroid = getCentroidByParcelId(parcelID)
    if (centroid) {
      hoverMarker = new mapboxgl.Marker()
          .setLngLat(centroid)
          .addTo(map.current)
    }
  }

  function handleOffHover() {
    if (hoverMarker) {
      hoverMarker.remove()
      hoverMarker = null
    }
  }

  const generatePopupHTML = function (ID) {
    const currentPropertyFeatures = propertyFeaturesRef.current
    const feature = currentPropertyFeatures.features.find(feature => feature.id === ID)

    const popupHTML = (`
      <div class="d-flex flex-column p-2 rounded shadow-sm">
        <div>
          <label class="form-label fs-6" for"labelInput-${ID}">Label</label>  
          <input class="form-control form-control-sm rounded" type="text" id="labelInput-${ID}" name="labelInput" placeholder="${feature?.properties?.label || "Add a label..."}" value="${feature?.properties?.label ? feature.properties.label : "" }">
        </div>
        <div class="d-flex flex-wrap">
          <label class="form-label fs-6 w-100">Color</label>
          <div class="d-flex w-75 ml-0 justify-content-start">
            <button class="btn btn-circle m-1 p-2" style="background-color: #FF0000; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('red', '${ID}')"></button>
            <button class="btn btn-circle m-1 p-2" style="background-color: #00FF00; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('green', '${ID}')"></button>
            <button class="btn btn-circle m-1 p-2" style="background-color: #0000FF; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('blue', '${ID}')"></button>
          </div>
          <div class="d-flex w-75 justify-content-start">
            <button class="btn btn-circle m-1 p-2" style="background-color: #FFFF00; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('yellow', '${ID}')"></button>
            <button class="btn btn-circle m-1 p-2" style="background-color: #FFA500; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('orange', '${ID}')"></button>
            <button class="btn btn-circle m-1 p-2" style="background-color: #800080; width: 30px; height: 30px; border-radius: 50%;" onclick="handleChangeColor('purple', '${ID}')"></button>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between mt-1">
          <button class="btn btn-danger w-50 me-1" id="deleteButton-${ID}">Delete</button>
          <button class="btn btn-primary w-50 ms-1" id="saveButton-${ID}">Save</button>
        </div>
      </div>
    `)
      return popupHTML
  }

  const handlePopupDelete = function (ID, popup) {
    const featureIndex = propertyFeaturesRef.current.features.findIndex(feature => feature.id === ID)
    if (featureIndex !== -1) {
      let updatedFeatures = propertyFeaturesRef.current
      updatedFeatures.features.splice(featureIndex, 1)

      setPropertyFeatures(updatedFeatures)

      draw.current.get(ID) 
        && draw.current.delete(ID)
      map.current.getLayer(`label-${ID}`) 
        && map.current.removeLayer(`label-${ID}`)
    }

    popup && popup.remove()
  }

  const handlePopupSave = function (ID, popup) {  
    const labelInput = document.getElementById(`labelInput-${ID}`);
    const labelValue = labelInput && labelInput.value !== 'Click to add label...' ? labelInput.value : '';

    const featureIndex = propertyFeaturesRef.current.features.findIndex(feature => feature.id === ID)
    if (featureIndex === -1) console.error('Feature not found')

    let updatedFeatures = { ...propertyFeaturesRef.current }
    let updatedFeature = { ...updatedFeatures.features[featureIndex] }
    if (!updatedFeature.properties) updatedFeature.properties = {}
    updatedFeature.properties.color = drawingColorRef.current
    updatedFeature.properties.label = labelValue
    if (!updatedFeature.properties.icon) updatedFeature.properties.icon = markerIconRef.current
 
    if (updatedFeature.properties.type === 'drawn-feature') {
      draw.current.delete(ID)
      draw.current.add(updatedFeature)
    }
  
    setPropertyFeatures(updatedFeatures)

    const labelSource = map.current.getSource(`label-${ID}`)
    if (labelSource) labelSource.setData(updatedFeature)

    popup && popup.remove()
  }

  return (
    <div>
      <div className="h-100 w-100 rounded d-flex my-1">

        {/* Land Owner Bank */}
        {addingProperty && (
          <div className="rounded mx-1 mb-1 p-2 px-2 d-flex flex-column align-items-center border bg-white overflow-auto" style={{width: '20vw', height: '85vh'}}>
            <h5 className="text-center m-2">Search Results</h5>

            <ul className="list-group w-100 cursor-pointer h-75 overflow-auto">
            {searchResults.length === 0 ? (
              <li className="d-flex justify-content-center text-center fw-bold pt-2">
                No results found, try another search.
              </li>
            ) : (
              <>
                {searchResults.slice(0, 10).map((result) => (
                  <li 
                    className="list-group-item list-group-item-action rounded-0 p-1 mb-1 d-flex"
                    style={{ cursor: 'pointer'}}
                    key={result.attributes.PARCELID}
                    onClick={() => zoomToParcel(result.attributes.PARCELID)}
                    onMouseEnter={() => handleOnHover(result.attributes.PARCELID)}
                    onMouseLeave={() => handleOffHover()}
                  >
                    {result.attributes.OwnerName}
                    <span className="text-success ms-auto p-0">
                      <Button 
                        onClick={() => {
                          handleOffHover()
                          handleParcelClick({ parcelID: result.attributes.PARCELID, ownerName: result.attributes.OwnerName })
                        }}                        
                        className="px-2 py-0 fs-6 ms-auto"
                        variant="success"
                      >
                        +
                      </Button>
                    </span>
                  </li>
                ))}
                {searchResults.length > 10 && (
                  <li className="d-flex flex-column text-center mt-3 text-muted">
                    More than 10 results found, try refining your search.
                  </li>
                )}
              </>
            )}
            </ul>

            {/* Side Panel Search */}
            {!searchModalOpen && (
                <div className="mt-auto w-100">
                <Form.Group className="w-100" >
                  <Form.Label className="d-block fs-6 form-label fw-medium m-0">State</Form.Label>
                  <Form.Control 
                    className="px-2 py-1" 
                    style={{color: '#a0a0a0'}} 
                    as="select" 
                    value={stateName} 
                    disabled 
                    onChange={(event) => setStateName(event.target.value)}
                  >
                    <option value={"Montana"}>Montana</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="w-100 mt-2">
                  <Form.Label className="d-block fs-6 form-label fw-medium m-0">County</Form.Label>
                  <Form.Control 
                    className="form-select px-2 py-1" 
                    as="select" 
                    value={countyName} 
                    onChange={(event) => setCountyName(event.target.value)}
                  >
                    {montanaCountyNames.map((county) => (
                      <option key={county} value={county}>
                        {county}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="w-100 mt-2">
                  <Form.Label 
                    className="d-block fs-6 form-label fw-medium m-0">Owner Name</Form.Label>
                  <Form.Control 
                    className="px-2 py-1"  
                    placeholder="Owner Name"
                    value={ownerName}
                    type="text" 
                    onChange={(event) => setOwnerName(event.target.value)} 
                  />
                </Form.Group>
                <Button 
                  className="w-100 mt-3" 
                  onClick={handleSubmit}
                  variant="secondary"              
                >
                  Search
                </Button>
                </div>
            )}

          </div>
        )}

        {/* Map */}
        <div ref={mapContainer} 
          className="map-container mx-1 m-0 rounded position-relative" 
          style={{
            width: `${addingProperty ? '60vw' : '100%'}`, 
            height: '85vh'
          }}>
          {addingProperty && 
            <ParcelSearch
              setOwnerName={setOwnerName}
              countyName={countyName}
              setCountyName={setCountyName}
              stateName={stateName}
              setStateName={setStateName}
              handleSubmit={handleSubmit}
              searchModalOpen={searchModalOpen}
            />
          }

          {addingProperty && 
            <ClaimParcels
              claimedParcels={claimedParcels}
              propertyName={propertyName}
              setPropertyName={setPropertyName}
              countyName={countyName}
              propertyCentroid={propertyCentroid}
              propertyBbox={propertyBbox}
              claimModalOpen={claimModalOpen}
            />
          }

          {(isDrawing) && 
            <DrawingTools 
              map={map} 
              draw={draw} 
              parcelsLineId={parcelsLineId}
              parcelsFillId={parcelsFillId}
              thisPropertyData={props.thisPropertyData}
              highlightedParcelsLineId={highlightedParcelsLineId}
              listingData={props.listingData}
              addingListing={addingListing}
              editingListing={editingListing}
              isAddingMarker={isAddingMarker}
              setIsAddingMarker={setIsAddingMarker}
              propertyFeatures={propertyFeatures}
              setPropertyFeatures={setPropertyFeatures}
              propertyFeaturesRef={propertyFeaturesRef}
              generatePopupHTML={generatePopupHTML}
              handlePopupSave={handlePopupSave}
              handlePopupDelete={handlePopupDelete}
              drawingColor={drawingColor}
              setDrawingColor={setDrawingColor}
              drawingColorRef={drawingColorRef}
              markerIcon={markerIcon}
              setMarkerIcon={setMarkerIcon}
              markerIconRef={markerIconRef}
            />
          }
        </div>

        {/* Selected Parcels */}
        {addingProperty && (
        <div className="rounded mx-1 p-2 px-2 d-flex flex-column align-items-center border bg-white" style={{width: '20vw', height: '85vh'}}>
          <h5 className="text-center m-2">Selected Parcels</h5>

          <div className="w-100 overflow-auto">
            {claimedParcels.length === 0 && (
              <p className="text-center fw-bold pt-2">
                Use the map to select parcels for your property.
              </p>
            )}

            {claimedParcels.map((parcel, index) => (
              <div key={index} className="d-flex mb-2 bg-light p-1 rounded">
                <p className="px-2 mb-0 fw-bold">{parcel.ownerName}</p>
                  <Button
                    className="bg-danger text-white p-0 ms-auto d-flex align-items-center justify-content-center"
                    style={{ height: '22px', width: '22px' }}
                    onClick={() => handleDeleteParcel(parcel)}
                  >
                    <p className="mb-0">x</p>
                  </Button>
              </div>
            ))}
          </div>
          
          <div className="mt-auto">
            <div
              className={`d-flex align-items-center py-2 ps-2 mb-1 rounded w-100 ${
                !ownershipConfirmed && areParcelsClaimed ? "bg-danger text-white" : ""
              }`}
            >
              <Form.Check 
                type="checkbox"
                onClick={(e) => {
                  setOwnershipConfirmed(e.target.checked)
                  setParcelsClaimed(false)
                }}
              />
              <label className="px-2">I confirm that I own the selected properties.</label>
            </div>

            <Button 
              className="w-100" 
              variant="secondary"
              onClick={() => handleClaimParcels()}
            >
              Claim Selected Parcels
            </Button>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}
export default Map
