import { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const PropertyMap = (props) => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
      if (props.isMapSelected && map.current) {
        map.current.resize()
        map.current.scrollZoom.disable();
        const zoomControlExists = map.current._controls && map.current._controls.some(control => control instanceof mapboxgl.NavigationControl);
        if (!zoomControlExists) map.current.addControl(new mapboxgl.NavigationControl());
        map.current.fitBounds(props.thisPropertyData.property_bbox, { padding: 80 });
      }
    }, [props.isMapSelected])

    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: `mapbox://styles/mapbox/satellite-streets-v12`,
              center: props.thisPropertyData.property_centroid,
              zoom: 10,
              pitch: 90
            });

            map.current.on('style.load', () => {
              map.current.addSource('mapbox-dem', {
                type: 'raster-dem',
                url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                tileSize: 512,
                maxzoom: 10
              });
              map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
            });
        } 

        map.current.on("load", () => {
            map.current.addSource('montana-parcels', {
                type: "vector",
                url: process.env.REACT_APP_MAPBOX_TILESET_URL,
            });

            map.current.on("sourcedata", function (e) {
              map.current.getLayer('parcels-line') 
                && map.current.removeLayer('parcels-line');
              map.current.addLayer({
                  source: 'montana-parcels',
                  id: 'parcels-line',
                  "source-layer": "montana-parcels",
                  type: "line",
                  paint: {
                    "line-color": "#ff00ff",
                    "line-opacity": 0.9,
                    "line-width": 3,
                  },
                  filter: [
                    "in",
                    "PARCELID",
                    ...props.thisPropertyData.parcels
                  ],
              });
          });
        });
    }, []);

    useEffect(() => {
      const handleTabShown = (event) => {
        if (event.target.getAttribute('eventKey') === 'map' && map.current) map.current.resize()
      }
    
      const addTabEventListeners = () => {
        const tabs = document.querySelectorAll('a[data-toggle="tab"]')
        tabs.forEach(tab => tab.addEventListener('shown.bs.tab', handleTabShown))

        return () => {
          tabs.forEach(tab => tab.removeEventListener('shown.bs.tab', handleTabShown))
        }
      }
      addTabEventListeners()
    }, []);
        
    return (
        <div style={{height: '800px', width: '100%'}}>
            <div ref={mapContainer} className="w-100 h-100 rounded"></div>
        </div>
    )
}

export default PropertyMap;