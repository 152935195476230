import { useState, useEffect, useRef, useParams } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from '@turf/turf';
import Cookies from "js-cookie";

const ListingMap = (props) => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN
  const mapContainer = useRef(null)
  const map = useRef(null)
  const defaultCenter = [-109.418, 47.037]

  // let currentUserData = Cookies.get('authToken');
  // currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  // let authToken = currentUserData.accessToken;

  useEffect(() => {
    const bbox = turf.bbox(props.listingData.map_geoJSON)
    const center = [
      (bbox[0] + bbox[2]) / 2,
      (bbox[1] + bbox[3]) / 2 
    ]
    const isValidLngLat = (lng, lat) => {
      return !isNaN(lng) && !isNaN(lat) && lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90;
    }
  
    const mapCenter = isValidLngLat(center[0], center[1]) ? center : defaultCenter
  
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/mapbox/satellite-streets-v12`,
        center: mapCenter,
        zoom: 10,
        pitch: 90
      })

      map.current.on('style.load', () => {
        map.current.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 10
        });
        map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      });
    } 

    map.current.on("load", () => {
      if (props.listingData) {

        map.current.getLayer('property-line') 
          && map.current.removeLayer('property-line');

        map.current.getSource('montana-parcels')
          && map.current.removeSource('montana-parcels')

        map.current.addSource('montana-parcels', {
          type: "vector",
          url: process.env.REACT_APP_MAPBOX_TILESET_URL,
        }); 

        map.current.addLayer({
            source: 'montana-parcels',
            id: 'property-line',
            "source-layer": "montana-parcels",
            type: "line",
            paint: {
              "line-color": "#ff00ff",
              "line-opacity": 0.9,
              "line-width": 3,
            },
            filter: [
              "in",
              "PARCELID",
              ...props.listingData.propertyData.parcels
            ],
        });

        const addedMarkers = new Set();

        props.listingData.map_geoJSON.features && props.listingData.map_geoJSON.features.forEach((feature, index) => {
          if (addedMarkers.has(feature.id)) return
          addedMarkers.add(feature.id)
          
          const layerId = `feature-${index}`;
          if (map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
            map.current.removeSource(layerId);
          }

          // Polygons and LineStrings
          if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'LineString') {
            map.current.addLayer({
              id: `feature-${index}`,
              type: 'line',
              source: {
                type: 'geojson',
                data: feature
              },
              paint: {
                'line-color': feature.properties?.color || '#00ffff',
                'line-opacity': 1,
                'line-width': 3,
              }
            }); 
          } 

          // Points
          if (feature.geometry.type === 'Point' && feature.properties?.type === 'drawn-feature') {
            if (!map.current.getLayer(`point-${index}`)) {
              map.current.addLayer({
                id: `point-${index}`,
                type: 'circle',
                source: {
                  type: 'geojson',
                  data: feature
                },
                paint: {
                  'circle-radius': 6,
                  'circle-color': feature.properties?.color || '#00ffff',
                  'circle-stroke-width': 2,
                  'circle-stroke-color': '#ffffff'
                }
              });
            }
          }

          // Markers with icons
          if (feature.geometry.type === 'Point' && feature.properties?.type === 'marker' ) {
          var el = document.createElement('div');
          el.className = 'marker';
          el.style.width = '40px';
          el.style.height = '40px';
         
          fetch(feature.properties.icon)
          .then(response => response.text())
          .then(svgContent => {
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
              const svgElement = svgDoc.documentElement;
              svgElement.setAttribute('fill', feature.properties.color || '#00ffff');
              const svgString = new XMLSerializer().serializeToString(svgElement);
              el.style.backgroundImage = `url('data:image/svg+xml;base64,${btoa(svgString)}')`;
          });

          el.style.backgroundSize = 'fit';
          el.style.backgroundRepeat = 'no-repeat';
          new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .addTo(map.current);
          }
          
          if (feature.properties && feature.properties.label) {
            const labelCoordinates = turf.centroid(feature).geometry.coordinates;

            if (!map.current.getSource(`label-source-${index}`)) {
              map.current.addSource(`label-source-${index}`, {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: labelCoordinates,
                  },
                  properties: {
                    label: feature.properties.label !== "Click to add label..." ? feature.properties.label : "",
                  }
                }
              });
            }
        
            if (!map.current.getLayer(`label-layer-${index}`)) {
              map.current.addLayer({
                id: `label-layer-${index}`,
                type: 'symbol',
                source: `label-source-${index}`,
                layout: {
                  'text-field': ['get', 'label'],
                  'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                  'text-radial-offset': 1.5,
                  'text-justify': 'auto',
                },
                paint: {
                  'text-color': '#ffffff', 
                  'text-halo-color': '#000000', 
                  'text-halo-width': 2, 
                }
              });
            }
          }
        })   
      }
    });
  }, [props])

  useEffect(() => {
    if (props.isMapSelected && map.current) {
      map.current.resize()
      map.current.scrollZoom.disable()
      const zoomControlExists = map.current._controls && map.current._controls.some(control => control instanceof mapboxgl.NavigationControl)
      if (!zoomControlExists) map.current.addControl(new mapboxgl.NavigationControl())
      const currentZoom = map.current.getZoom()
      map.current.setZoom(currentZoom)
      
      const onIdle = () => {
        const features = map.current.queryRenderedFeatures({ layers: ['property-line'] })
        if (features.length === 0) {
          return
        }

        const geojson = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: features[0].geometry.coordinates
          }
        }
        const bbox = turf.bbox(geojson);
        map.current.fitBounds(bbox, { padding: 80 })
        map.current.off('idle', onIdle)
      }
      map.current.on('idle', onIdle);
    }
  }, [props.isMapSelected])
        
  return (
      <div style={{height: '800px', width: '100%'}}>
          <div ref={mapContainer} className="w-100 h-100 rounded"></div>
      </div>
  )
}

export default ListingMap;