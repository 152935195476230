import React from "react";
import { Routes } from "../../routes";
import queryString from "query-string";

import { SearchListings } from "../../components/forms/searchPublicListings";
import { masterCategoryDefinitions } from "../../listingTypes/listingTypes";
import { statesList } from "../../components/data/statesList";

import PublicFooter from '../../components/PublicFooter';

/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
*/

import { Col, Row, Container } from "react-bootstrap";


const Homepage = (props) => {

  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Send to search page w/ hash parameters
    // console.log(Routes.Search.path+'#'+queryHash);
  }

  return (
    <>
      <section className="section-header pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block"><span className="flaticon-fishing"></span></div>
              <h1 className="fw-bolder text-secondary">REC-X</h1>
              <p className="text-muted fw-light mb-5 h5">The Marketplace for Unique Recreational Experiences</p>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>

          <SearchListings masterCategoryDefinitions={masterCategoryDefinitions} formData={searchListingsFormData} searchListings={searchListings} statesList={statesList} />

        </Container>
      </section>

      <div className="section pt-0 mt-5">
        <Container className="mt-n10 mt-lg-n12">

          <Row className="justify-content-center">
            <Col xs={12}>
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%" /* 16:9 */,
                  paddingTop: 25,
                  height: 0
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  src={`https://www.youtube.com/embed/w05WVVCJTDI?controls=0&autoplay=1&mute=1`}
                  frameBorder="0"
                  title="Montana Outdoor Recreation film reel"
                  allow="mute; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5 mt-lg-6">
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-secondary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">20</h3>
              <p className="text-gray">Blue Ribbon Fishing</p>
            </Col>
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-secondary mb-4">
                <span className="flaticon-deer"></span>
              </div>
              <h3 className="fw-bolder">50+</h3>
              <p className="text-gray">Big Game Hunting</p>
            </Col>
            <Col xs={6} md={4} className="text-center">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-secondary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">22</h3>
              <p className="text-gray">Private Pond Fishing</p>
            </Col>
          </Row>
        </Container>
      </div>


      <PublicFooter />
    </>
  );
};
export default Homepage;
